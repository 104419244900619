import { useEffect, useState } from "react";
import api from "../services/api";
import moment from "moment";
import Budget from "../pages/Budget";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useAuth } from "../context/useAuth";

export default function EmployeEdit({ employee, index, list, setList, close }) {
  const [employe, setEmploye] = useState({ ...employee });
  const [editMode, setEditMode] = useState();
  const { user } = useAuth();
  const [listAutocomplete, setListAutocomplete] = useState(
    list
      ? list.map((e) => {
          return { value: e.hrCode, name: e.hrCode + " - " + e.firstName + " " + e.lastName };
        })
      : []
  );

  useEffect(() => {
    const fetchData = async () => {
      let data = await api.getEmployees();
      setListAutocomplete(
        data.data.map((e) => {
          return { value: e.hrCode, name: e.hrCode + " - " + e.firstName + " " + e.lastName };
        })
      );
    };

    if (!list) fetchData();
  }, [list]);

  const setValue = (key, value) => {
    employe[key] = value;
    setEmploye({ ...employe });
  };

  const save = async () => {
    let data = await api.saveEmploye(employe);

    if (data.status === 200 && list) {
      list[index] = data.data;
      setList([...list]);
    }
  };

  return (
    <div className="modal-edit-employee">
      <div className="modal-header">View/Edit Person</div>

      <div className="modal-body">
        <div className="row">
          <div className="col-2">
            <div className="subtitle">Employee Info</div>

            <div className="row">
              <div className="col-2">
                <div className="label-row">
                  <label>First Name</label>
                  <input value={employe.firstName} onChange={(e) => setValue("firstName", e.target.value)} disabled></input>
                </div>
              </div>
              <div className="col-2">
                <div className="label-row">
                  <label>Last Name</label>
                  <input value={employe.lastName} onChange={(e) => setValue("lastName", e.target.value)} disabled></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="label-row">
                <label>Email</label>
                <input value={employe.email} onChange={(e) => setValue("email", e.target.value)} disabled></input>
              </div>
            </div>

            {/* BOS */}
            <div className="row">
              <div className="col-2">
                <div className="label-row">
                  <label>HR Code</label>
                  <input value={employe.hrCode} onChange={(e) => setValue("hrCode", e.target.value)} disabled></input>
                </div>
              </div>
              <div className="col-2">
                <div className="label-row">
                  <label>HR Alias</label>
                  <input value={employe.hrAlias} onChange={(e) => setValue("hrAlias", e.target.value)} disabled></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-2">
                <div className="label-row">
                  <label>TW Office</label>
                  <input value={employe.twOffice} onChange={(e) => setValue("twOffice", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>Team</label>
                  <input value={employe.teamName} onChange={(e) => setValue("teamName", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>Payroll Source</label>
                  <input
                    value={employe.payrollSource}
                    onChange={(e) => setValue("payrollSource", e.target.value)}
                    disabled
                  ></input>
                </div>
                <div className="label-row">
                  <label>Currency</label>
                  <input value={employe.currencyCode} onChange={(e) => setValue("currencyCode", e.target.value)} disabled></input>
                </div>
              </div>
              <div className="col-2">
                <div className="label-row">
                  <label>Department</label>
                  <input
                    value={employe.departmentName}
                    onChange={(e) => setValue("departmentName", e.target.value)}
                    disabled
                  ></input>
                </div>
                <div className="label-row">
                  <label>Division</label>
                  <input value={employe.divisionName} onChange={(e) => setValue("divisionName", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>Position</label>
                  <input value={employe.position} onChange={(e) => setValue("position", e.target.value)} disabled></input>
                </div>
                <div className="label-row">
                  <label>Location</label>
                  <input value={employe.location} onChange={(e) => setValue("location", e.target.value)} disabled></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="label-row">
                <label>Pay Supervisor</label>

                {!editMode ? (
                  <>
                    {user.role === "admin" && (
                      <button className="small green" onClick={() => setEditMode(true)}>
                        Edit
                      </button>
                    )}

                    <input
                      value={
                        employe._paySupervisor !== undefined &&
                        listAutocomplete.find((item) => item.value === employe._paySupervisor)?.name
                      }
                      disabled
                    ></input>
                  </>
                ) : (
                  <div className="autocomplete-wrapper">
                    <ReactSearchAutocomplete
                      value={employe._paySupervisor}
                      items={listAutocomplete}
                      autoFocus
                      onSelect={(e) => {
                        setValue("_paySupervisor", e.value);
                        save();
                      }}
                    />
                    <button
                      className="medium green"
                      style={{ marginTop: 20 }}
                      onClick={() => {
                        save();
                        setEditMode(false);
                      }}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-2">
            {/* Financials */}
            <div className="subtitle">History</div>

            {employe.history &&
              employe.history.map((h, i) => (
                <div key={"h_" + i}>
                  <div className="row">
                    <div className="col-2">
                      <div className="label-row">
                        <label>Start Date</label>
                        {i === 0
                          ? employe.startDate && moment(employe.startDate).format("DD MMMM YYYY")
                          : employe.history[i - 1].endDate && moment(employe.history[i - 1].endDate).format("DD MMMM YYYY")}
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="label-row">
                        <label>End Date</label>
                        {h.endDate && moment(h.endDate).format("DD MMMM YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-2">
                      <div className="label-row">
                        <label>Office / Division / Department</label>
                        {h.twOffice} / {h.divisionName} / {h.departmentName}
                      </div>

                      <div className="label-row">
                        <label>Payroll Source / Location</label>
                        {h.payrollSource} / {h.location}
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="label-row">
                        <label>Team / Position</label>
                        {h.teamName} / {h.position}
                      </div>

                      <div className="label-row">
                        <label>Currency</label>
                        {h.currencyCode}
                      </div>
                    </div>
                  </div>

                  {employe.history.length !== i + 1 && <hr className="mb-30"></hr>}
                </div>
              ))}
            {employe.history && employe.history.length === 0 && <div>No history data</div>}
          </div>
        </div>

        <hr></hr>
        <Budget singleView={true} filterInit={{ year: moment().format("YYYY"), hrCode: employe.hrCode }}></Budget>
      </div>

      <div className="modal-footer">
        <div className="btn-bar-left"></div>
        <div className="btn-bar-right">
          {/* <button className="link" onClick={close}>
            Cancel
          </button>
          <button className="green" onClick={save}>
            Update
          </button> */}
          <button className="green" onClick={() => close()}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
