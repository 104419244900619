import { useState } from "react";
import api from "../../services/api";
import Modal from "../Modal";
import { currencyFormatExcelNum } from "../../services/utils";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default function ExportComparison({ teamName, filter, setShowModalExportComparison, showModalExportComparison }) {
  const [optionExport, setOptionExport] = useState({
    compare: true,
    showBase: true,
    showBonus: true,
    showOther: true,
    showLoaded: true,
    showTeam: filter.groupBy ? false : true,
    currency: filter.groupBy ? "USD" : "",
  });

  const exportComparison = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report", { views: [{ showGridLines: false }] });

    worksheet.properties.outlineProperties = {
      summaryBelow: false,
      summaryRight: false,
    };

    let getDataApi = api.getBudgetData;

    if (filter.groupBy) {
      getDataApi = api.getBudgetGroupBy;
    }

    let budget = await getDataApi(
      { ...filter, compare: true },
      {
        curPage: 1,
        pageSize: 999999,
      }
    );

    let numFmt = "#,##0.00";
    let columns = [
      // { header: "level", key: "level" },
      { header: "Year", key: "Year" },
      { header: "P/A", key: "P/A" },
      { header: "Type", key: "Type" },
      { header: "Location", key: "Location" },
      { header: "Source", key: "Source" },
      { header: "Currency", key: "Currency" },
      { header: "Division", key: "Division" },
      { header: "Department", key: "Department" },
      { header: "Team", key: "Team" },
      { header: "Position", key: "Position" },
      { header: "EE No.", key: "EE No." },
      { header: "First Name", key: "First Name" },
      { header: "Last Name", key: "Last Name" },
      {
        header: "Jan - Native",
        key: "Jan - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Jan - USD",
        key: "Jan - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Feb - Native",
        key: "Feb - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Feb - USD",
        key: "Feb - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Mar - Native",
        key: "Mar - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Mar - USD",
        key: "Mar - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Apr - Native",
        key: "Apr - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Apr - USD",
        key: "Apr - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "May - Native",
        key: "May - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "May - USD",
        key: "May - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Jun - Native",
        key: "Jun - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Jun - USD",
        key: "Jun - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Jul - Native",
        key: "Jul - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Jul - USD",
        key: "Jul - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Aug - Native",
        key: "Aug - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Aug - USD",
        key: "Aug - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Sep - Native",
        key: "Sep - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Sep - USD",
        key: "Sep - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Oct - Native",
        key: "Oct - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Oct - USD",
        key: "Oct - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Nov - Native",
        key: "Nov - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Nov - USD",
        key: "Nov - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Dec - Native",
        key: "Dec - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "Dec - USD",
        key: "Dec - USD",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "13th - Native",
        key: "13th - Native",
        style: {
          numFmt: numFmt,
        },
      },
      {
        header: "13th - USD",
        key: "13th - USD",
        style: {
          numFmt: numFmt,
        },
      },
    ];

    if (optionExport["currency"] === "USD" || filter.groupBy) {
      columns.splice(5, 1);
      columns.splice(10, 1);
      columns.splice(11, 1);
      columns.splice(12, 1);
      columns.splice(13, 1);
      columns.splice(14, 1);
      columns.splice(15, 1);
      columns.splice(16, 1);
      columns.splice(17, 1);
      columns.splice(18, 1);
      columns.splice(19, 1);
      columns.splice(20, 1);
      columns.splice(21, 1);
      columns.splice(22, 1);
      columns.splice(23, 1);
      columns.splice(24, 1);
    }

    if (optionExport["currency"] === "Native" && !filter.groupBy) {
      columns.splice(14, 1);
      columns.splice(15, 1);
      columns.splice(16, 1);
      columns.splice(17, 1);
      columns.splice(18, 1);
      columns.splice(19, 1);
      columns.splice(20, 1);
      columns.splice(21, 1);
      columns.splice(22, 1);
      columns.splice(23, 1);
      columns.splice(24, 1);
      columns.splice(25, 1);
      columns.splice(26, 1);
    }

    if (filter.groupBy) {
      if (filter.groupBy === "Team") {
        columns.splice(3, 0, { header: "Team", key: "Team" });
      }
      if (filter.groupBy === "Department") {
        columns.splice(3, 0, { header: "Department", key: "Department" });
      }
      columns.splice(4, 1);
      columns.splice(4, 1);
      columns.splice(4, 1);
      columns.splice(4, 1);
      columns.splice(4, 1);
      columns.splice(4, 1);
      columns.splice(4, 1);
      columns.splice(4, 1);
    }

    worksheet.columns = columns;

    let data = [];
    let teamTotal = {
      months: [],
    };
    let teamStartRow = 1;

    for (let r in budget.data.rows) {
      let e = budget.data.rows[r];

      if (optionExport["showBase"]) {
        addLine(worksheet, data, e, parseInt(filter.year), "A", "salary", optionExport["showTeam"] && 1);
      }
      if (optionExport["showBonus"]) {
        addLine(worksheet, data, e, parseInt(filter.year), "A", "bonus", optionExport["showTeam"] && 1);
      }
      if (optionExport["showOther"]) {
        addLine(worksheet, data, e, parseInt(filter.year), "A", "other", optionExport["showTeam"] && 1);
      }
      if (optionExport["showLoaded"]) {
        addLine(worksheet, data, e, parseInt(filter.year), "A", "total", optionExport["showTeam"] && 1);
      }
      if (optionExport["showBase"] && optionExport["compare"]) {
        addLine(worksheet, data, e, parseInt(filter.year) + 1, "P", "salary", optionExport["showTeam"] && 1);
      }
      if (optionExport["showBonus"] && optionExport["compare"]) {
        addLine(worksheet, data, e, parseInt(filter.year) + 1, "P", "bonus", optionExport["showTeam"] && 1);
      }
      if (optionExport["showOther"] && optionExport["compare"]) {
        addLine(worksheet, data, e, parseInt(filter.year) + 1, "P", "other", optionExport["showTeam"] && 1);
      }
      if (optionExport["showLoaded"] && optionExport["compare"]) {
        addLine(worksheet, data, e, parseInt(filter.year) + 1, "P", "total", optionExport["showTeam"] && 1);
      }

      // Border line employee
      worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
        cell.border = {
          bottom: { style: "thin", color: "5E5E5E" },
        };
      });

      if (optionExport["showTeam"]) {
        teamTotal.employee = {
          divisionName: e.employee?.divisionName,
          departmentName: e.employee?.departmentName,
          teamName: e.employee?.teamName,
        };

        // Sum actual team value
        for (let m = 0; m < 13; m++) {
          if (!teamTotal.months[m]) {
            teamTotal.months[m] = {
              salaryActual_USD: 0,
              bonusActual_USD: 0,
              otherActual_USD: 0,
              totalActual_USD: 0,
              salaryPlan_USD: 0,
              bonusPlan_USD: 0,
              otherPlan_USD: 0,
              totalPlan_USD: 0,
            };
          }

          teamTotal.months[m]["salaryActual_USD"] += e.months[m].salaryActual_USD;
          teamTotal.months[m]["bonusActual_USD"] += e.months[m].bonusActual_USD;
          teamTotal.months[m]["otherActual_USD"] += e.months[m].otherActual_USD;
          teamTotal.months[m]["totalActual_USD"] += e.months[m].totalActual_USD;
          teamTotal.months[m]["salaryPlan_USD"] += e.months[m].salaryPlan_USD;
          teamTotal.months[m]["bonusPlan_USD"] += e.months[m].bonusPlan_USD;
          teamTotal.months[m]["otherPlan_USD"] += e.months[m].otherPlan_USD;
          teamTotal.months[m]["totalPlan_USD"] += e.months[m].totalPlan_USD;
        }

        r = parseInt(r);
        let nextTeam = budget.data.rows[r + 1] ? budget.data.rows[r + 1].employee?.teamId : null;
        if (nextTeam !== e.employee?.teamId) {
          // Add team line
          if (optionExport["showBase"]) {
            teamStartRow++;
            addLine(worksheet, data, teamTotal, parseInt(filter.year), "A", "salary", 0, teamStartRow);
          }
          if (optionExport["showBonus"]) {
            teamStartRow++;
            addLine(worksheet, data, teamTotal, parseInt(filter.year), "A", "bonus", 0, teamStartRow);
          }
          if (optionExport["showOther"]) {
            teamStartRow++;
            addLine(worksheet, data, teamTotal, parseInt(filter.year), "A", "other", 0, teamStartRow);
          }
          if (optionExport["showLoaded"]) {
            teamStartRow++;
            addLine(worksheet, data, teamTotal, parseInt(filter.year), "A", "total", 0, teamStartRow);
          }
          if (optionExport["showBase"] && optionExport["compare"]) {
            teamStartRow++;
            addLine(worksheet, data, teamTotal, parseInt(filter.year) + 1, "P", "salary", 0, teamStartRow);
          }
          if (optionExport["showBonus"] && optionExport["compare"]) {
            teamStartRow++;
            addLine(worksheet, data, teamTotal, parseInt(filter.year) + 1, "P", "bonus", 0, teamStartRow);
          }
          if (optionExport["showOther"] && optionExport["compare"]) {
            teamStartRow++;
            addLine(worksheet, data, teamTotal, parseInt(filter.year) + 1, "P", "other", 0, teamStartRow);
          }
          if (optionExport["showLoaded"] && optionExport["compare"]) {
            teamStartRow++;
            addLine(worksheet, data, teamTotal, parseInt(filter.year) + 1, "P", "total", 0, teamStartRow);
          }

          // Border team style
          worksheet.getRow(teamStartRow + 1).eachCell((cell) => {
            cell.border = {
              top: { style: "thin", color: "5E5E5E" },
            };
          });

          // Reset
          teamTotal = {
            months: [],
          };

          teamStartRow = worksheet.rowCount;
        }
      }

      // Bold line
    }

    // Apply styles to the header row
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "BCC0BF" },
        bgColor: { argb: "BCC0BF" },
      };
      cell.font = {
        bold: true,
        color: { argb: "000" },
      };
    });

    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the Excel file
    let name = `Export Comparison ${teamName || "All"} - ${filter.year} - ${optionExport.currency || "Both Currency"}.xlsx`;
    saveAs(new Blob([buffer]), name);
  };

  const addLine = (worksheet, data, e, year, pa, type, level, rowNum) => {
    let employee = {
      Year: year,
      "P/A": pa,
      Type: type,
      Location: e.employee?.location,
      Source: e.employee?.payrollSource,
      Currency: e.employee?.currencyCode || "USD",
      Division: e.employee?.divisionName,
      Department: e.employee?.departmentName || e.departmentName,
      Team: e.employee?.teamName || e.teamName,
      Position: e.employee?.position,
      "EE No.": e.employee?.hrCode,
      "First Name": e.employee?.firstName,
      "Last Name": e.employee?.lastName,
    };

    if (pa === "P") pa = "Plan";
    if (pa === "A") pa = "Actual";
    let row = {
      ...employee,
      "Jan - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[0][type + pa]),
      "Jan - USD": currencyFormatExcelNum(e.months[0][type + pa + "_USD"]),
      "Feb - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[1][type + pa]),
      "Feb - USD": currencyFormatExcelNum(e.months[1][type + pa + "_USD"]),
      "Mar - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[2][type + pa]),
      "Mar - USD": currencyFormatExcelNum(e.months[2][type + pa + "_USD"]),
      "Apr - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[3][type + pa]),
      "Apr - USD": currencyFormatExcelNum(e.months[3][type + pa + "_USD"]),
      "May - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[4][type + pa]),
      "May - USD": currencyFormatExcelNum(e.months[4][type + pa + "_USD"]),
      "Jun - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[5][type + pa]),
      "Jun - USD": currencyFormatExcelNum(e.months[5][type + pa + "_USD"]),
      "Jul - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[6][type + pa]),
      "Jul - USD": currencyFormatExcelNum(e.months[6][type + pa + "_USD"]),
      "Aug - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[7][type + pa]),
      "Aug - USD": currencyFormatExcelNum(e.months[7][type + pa + "_USD"]),
      "Sep - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[8][type + pa]),
      "Sep - USD": currencyFormatExcelNum(e.months[8][type + pa + "_USD"]),
      "Oct - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[9][type + pa]),
      "Oct - USD": currencyFormatExcelNum(e.months[9][type + pa + "_USD"]),
      "Nov - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[10][type + pa]),
      "Nov - USD": currencyFormatExcelNum(e.months[10][type + pa + "_USD"]),
      "Dec - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[11][type + pa]),
      "Dec - USD": currencyFormatExcelNum(e.months[11][type + pa + "_USD"]),
      "13th - Native": e.employee?.hrCode && currencyFormatExcelNum(e.months[12][type + pa]),
      "13th - USD": currencyFormatExcelNum(e.months[12][type + pa + "_USD"]),
    };

    let rowXls;
    row.level = level;
    if (rowNum) {
      rowXls = worksheet.insertRow(rowNum, row);
      rowXls.eachCell({ includeEmpty: true }, (cell) => {
        cell.font = {
          bold: true,
          color: { argb: "000" },
        };
      });
    } else {
      rowXls = worksheet.addRow(row);
    }

    // Style plan
    if (pa === "Plan") {
      rowXls.eachCell({ includeEmpty: true }, (cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F2F2F2" },
          bgColor: { argb: "F2F2F2" },
        };
      });
    }

    // Style year
    rowXls.getCell("Year").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "DBDBDB" },
      bgColor: { argb: "DBDBDB" },
    };

    // if (level !== undefined) {
    //   rowXls.outlineLevel = level;
    //   console.log(rowXls.outlineLevel);
    // }
  };

  const setExportValue = (key, value) => {
    let set = {};
    set[key] = value;
    setOptionExport({ ...optionExport, ...set });
  };

  return (
    <>
      {showModalExportComparison && (
        <Modal size={"small"} close={() => setShowModalExportComparison(false)}>
          <div className="modal-header">Export Year Comparison</div>

          <div className="modal-body">
            <div className="subtitle">Select options</div>

            {!filter.groupBy && (
              <div className="label-row">
                <label>Currency</label>
                <span className="align-right">
                  <select value={optionExport["currency"]} onChange={(e) => setExportValue("currency", e.target.value)}>
                    <option value={""}>Both</option>
                    <option value={"USD"}>Only USD</option>
                    <option value={"Native"}>Only Native Currency</option>
                  </select>
                </span>
              </div>
            )}

            {!filter.groupBy && (
              <div className="label-row vertical-top">
                <label>Team Total</label>
                <div>
                  <div className="content-checkbox">
                    <input
                      type="checkbox"
                      checked={optionExport["showTeam"]}
                      onChange={(e) => setExportValue("showTeam", e.target.checked)}
                    ></input>
                    Show
                  </div>
                </div>
              </div>
            )}

            <div className="label-row vertical-top">
              <label>Show columns</label>
              <div>
                <div className="content-checkbox">
                  <input
                    type="checkbox"
                    checked={optionExport["showBase"]}
                    onChange={(e) => setExportValue("showBase", e.target.checked)}
                  ></input>
                  Base
                </div>
                <div className="content-checkbox">
                  <input
                    type="checkbox"
                    checked={optionExport["showBonus"]}
                    onChange={(e) => setExportValue("showBonus", e.target.checked)}
                  ></input>
                  Bonus
                </div>
                <div className="content-checkbox">
                  <input
                    type="checkbox"
                    checked={optionExport["showOther"]}
                    onChange={(e) => setExportValue("showOther", e.target.checked)}
                  ></input>
                  Other
                </div>
                <div className="content-checkbox">
                  <input
                    type="checkbox"
                    checked={optionExport["showLoaded"]}
                    onChange={(e) => setExportValue("showLoaded", e.target.checked)}
                  ></input>
                  Loaded
                </div>
              </div>
            </div>
            <div className="label-row vertical-top">
              <label>Next year</label>
              <div>
                <div className="content-checkbox">
                  <input
                    type="checkbox"
                    checked={optionExport["compare"]}
                    onChange={(e) => setExportValue("compare", e.target.checked)}
                  ></input>
                  Enable
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <div className="btn-bar-left"></div>
            <div className="btn-bar-right">
              <button className="green" onClick={() => exportComparison()}>
                Export
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
